.constricted {
  max-width: 1280px;
  margin: auto;
  padding: 0 20px;

  img {
    max-width: 100%;
    height: auto;
  }
}

.constricted-xs {
  max-width: rem(780);
  margin: auto;
  padding: 0 20px;

  .constricted {
    padding: 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}
