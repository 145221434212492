$icons: (
  'vaste-job',
  'durf',
  'resultaatgericht',
  'samen',
  'plezier',
  'traineeships',
  'elevator',
  'training',
  'groei-en-bloei',
  'authenticiteit'
  );

.icn {
  @include size(80px);
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @each $name in $icons {
    &--#{$name} {
      background-image: url('../img/icons/icn-#{$name}@2x.png');
    }
  }
}
