.custom-checkbox.error,
.form-check.error {
  label {
    color: $red;
  }
}

.form-check {
  .has-error {
    + label {
      color: $red;
    }
  }
}

.custom-checkbox input[type="checkbox"],
.form-check input[type="checkbox"] {
  position: absolute;
  left: -9999em;

  & + label {
    user-select: none;
    display: inline-block;
    position: relative;
    padding-left: 35px;
    line-height: 22px;
    cursor: pointer;


    &::before {
      @include size(20px);
      content: '';
      display: block;
      position: absolute;
      transition: all .3s ease-in-out;
      border: 1px solid $orange;
      top: 0;
      left: 0;
    }
  }

  &:checked + label {
    &::before {
      background-color: $orange;
    }
  }
}



.checkboxgroup-inline {
  margin-bottom: 20px;

  .custom-checkbox {
    margin: 10px 0 0 0;
  }
}


@include above($tablet) {
  .checkboxgroup-inline {
    .custom-checkbox,
    .form-check {
      display: inline-block;
    }
    .custom-checkbox + .custom-checkbox,
    .form-check + .form-check {
      margin: 0 0 0 45px;
    }
  }
}
