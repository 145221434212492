.companies {

  h1,
  h2 {
    margin-bottom: rem(10);
    text-align: center;
  }


  .company {

    &-slider {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;

      @include below($desktop-md) {
        width: 90%;
        margin: 0 auto;
      }

      img {
        max-width: rem(150);
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
      }

      a {
        display: block;

        &:after {
          content: none;
          display: none;
        }
      }

      .slick-arrow {
        margin-top: 0;
        transform: translateY(-50%);
      }

      .slick-track {
        display: flex;
      }

      .slick-track .slick-slide {
        display: flex;
        height: auto;
        align-items: center;
        justify-content: center;
      }
    }

    &-item {
      padding: 0 rem(20);
    }
  }

}
