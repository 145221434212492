.service {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;


  &__item {
    position: relative;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    width: 50%;
    margin: 10px 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 1em;
      width: 1px;
      height: 50px;
      background-color: #BBBBBB;
    }

    &:nth-child(odd) {
      &::before {
        display: none;
      }
    }

    a::after { display: none; }

    span { display: block; }

    .icn {
      @include size(60px);
      background-size: contain;
      margin: 10px 0;
    }

  }
}


@include above($tablet-xs) {
  .service {
    flex-wrap: nowrap;

    &__item {
      width: 25%;

      &:nth-child(odd) {
        &::before {
          display: block;
        }
      }

      &:nth-child(4n + 1) {
        margin-left: 0;
        &::before {
          display: none;
        }
      }

      .icn {
        margin: 0;
        position: relative;
      }

      a:hover {
        cursor: pointer;
        .icn {
          @include colorize-icon($pink);
        }
      }
    }
  }
}

@include above($tablet) {
  .service {

    &__item {
      font-size: 16px;

      &::before {
        top: 1em;
        height: 55px;
      }

      .icn {
        @include size(100px);
        background-size: contain;
      }
    }
  }
}

@include above($desktop) {
  .service {

    &__item {
      font-size: 18px;

      &::before {
        top: 30px;
        height: 66px;
      }

      .icn {
        @include size(120px);
        background-size: contain;
      }
    }
  }
}


@include above($desktop-hd) {
  .service {

    &__item {
      padding: 0 20px;
      font-size: 20px;
      margin-left: 20px;

      &::before {
        top: 30px;
        height: 66px;
      }
    }
  }
}
