.section {
  h1, h2 {
    @include above($tablet) {
      margin-bottom: rem(30);
    }

    &.centered {
      text-align: center;
    }
  }

}
