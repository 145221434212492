.events-overview__inner {
  display: flex;
  flex-wrap: wrap;
  padding: 4%;

  .event {
    padding: 20px 4%;
  }
}

.events-overview__cta {
  padding: 4%;
  text-align: center;
}

@include above($mobile) {
  .events-overview__inner {
    .event {
      width: 100%;
    }
  }
}

@include above($tablet-xs) {
  .events-overview__inner {
    .event {
      width: 33.333%;
    }
  }
}


// @include above($desktop) {
//   .events-overview__inner {
//     .event {
//       width: 25%;
//     }
//   }
// }
