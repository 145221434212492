$headers: 'h1, h2, h3, h4, h5, h6';

#{$headers} {
  color: $primary-color;
  margin: 0;
  font-weight: 400;

  a {
    color: inherit;
    text-decoration: none;
  }
}

* {
  &:focus {
    outline: none;
  }
}

body {
  overflow-x: hidden;
}


h1 {
  $fs: 42;
  font-size: px($fs);
  letter-spacing: ls(1.17, $fs);
  font-weight: 300;
  margin-bottom: rem(20);

  @include below($tablet){
    $fs: 24;
    font-size: px($fs);
    letter-spacing: ls(1.17, $fs);
  }
}

h2 {
  $fs: 20;
  font-size: px($fs);
  letter-spacing: ls(.17, $fs);
  font-weight: 400;
  margin-bottom: rem(10);
}


figure {
  margin: 0;
}

main {
  > section {
    &:first-child {

      &.hero,
      &.hero-slider,
      &.subnav {
        margin-top: 0;
      }

      &.subnav {
        margin-bottom: 0;
      }

      &.subnav + .hero,
      &.subnav + .hero-slider {
        margin-top: 0;
      }
    }

    &:last-child {

      &.instafeed {
        margin-bottom: 0;
      }
    }
  }
}


button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.block__visual {
  @include above($tablet){
    margin-top: 13px;
  }
}
