.hero-slider {
  height: 400px;
  overflow: hidden;
}

body.page-home .hero {
  background-position: 0;
}

.hero {
  height: 400px;
  position: relative;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  @include above($tablet-xs) {
    text-align: left;
  }

  &--overlay {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(#000000, .2);
    }
  }

  &--right {
    .hero__content {
      justify-content: flex-end;
    }
  }

  &--left {
    .hero__content {
      justify-content: flex-start;
    }
  }

  &--center {
    .hero__content {
      justify-content: center;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &__content__inner {
    width: 100%;
    color: $white;

    @include above($tablet-xs) {
      width: 70%;
    }

    @include above($tablet) {
      width: 50%;
    }

    @include above($desktop) {
      width: 40%;
    }

    .btn--primary,
    .btn--secondary {
      margin-right: rem(10);
      margin-top: rem(10);
      margin-bottom: rem(10);
    }
  }

  &__title {
    $fs: 30;
    font-size: px($fs);
    letter-spacing: ls(.19, $fs);
    font-family: $sans-font-family-alt;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    color: inherit;

    @include above($tablet-xs) {
      $fs: 38;
      font-size: px($fs);
      letter-spacing: ls(.19, $fs);
    }

    @include above($desktop) {
      $fs: 42;
      font-size: px($fs);
      letter-spacing: ls(.19, $fs);
    }
  }

  &__description {
    p {
      margin-top: 1em;
    }
  }

  a {
    color: $pink;
  }

  a {
    &.btn--primary {
      color: $white;
    }

    &.btn--secondary {
      color: $teal;

      &:hover {
        color: $pink;
      }
    }
  }

  // a {
  //   @extend .btn;
  //   @extend .btn--secondary;
  //   margin: 10px 0 0 0;

  //   @include below($mobile){
  //     min-width: auto;
  //   }

  //   &:last-child {
  //     margin-right: 0;
  //   }

  //   @include above($tablet) {
  //     display: inline-block;
  //     margin: 10px 10px 0 0;
  //   }

  //   @include above($desktop) {
  //     margin: 10px 20px 0 0;

  //     &:last-child {
  //       margin-right: 0;
  //     }
  //   }
  // }
}
