.employee {
  text-align: center;

  &__portrait {
    a { display: inline-block; }
    img { display: block; }
  }

  &__name {
    $fs: 24;
    font-size: px($fs);
    letter-spacing: ls(.2, $fs);
    font-weight: 600;
    text-transform: uppercase;
  }

  &__jobtitle {
    $fs: 16;
    margin: 0;
    color: $secondary-color;
    font-family: $serif-font-family;
    font-size: px($fs);
    letter-spacing: ls(.69, $fs);
    font-weight: 700;

    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
