.site-header {
  background-color: $white;
  position: sticky;
  z-index: 99;
  top: 0;
  box-shadow: 0 0 5px rgba(black, 0.2);
  padding: 10px 0 10px;

  &__inner {
    height: 100%;
  }

  // .logo {
  //   margin-left: 27px;
  // }
}

@include above($mobile-menu) {
  .site-header {
    height: 84px;
    padding: 0;

    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
