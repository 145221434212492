.nav--legal {
  $fs: 14;
  font-family: $sans-font-family-alt;
  font-size: px($fs);
  color: $white;

  .nav__item + .nav__item::before {
    content: ' | ';
  }

  a { color: inherit; }
}
