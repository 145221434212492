.tti {

  &--right {
    .tti__visual {
      order: 2;
    }
  }

  &--left {
    .tti__visual {
      order: 0;
    }
  }

  &--has-values {
    .tti__visual {
      max-width: rem(530);
      margin: -30px 0;

      @include below($desktop) {
        margin: 30px auto;
        margin-top: 0;
      }

    }
  }

  &--switch-mobile-layout {
    .tti__visual {
      @include below($tablet) {
        order: 2;
        margin-bottom: 0;
      }
    }
  }

  .section__inner {
    position: relative;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    @include below($tablet) {
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  &__visual {
    width: 50%;
    margin-bottom: 0;

    img {
      width: 100%;
      height: auto;
    }

    @include below($tablet) {
      width: 100%;
      margin-bottom: rem(20);
    }

    &-caption {
      font-size: rem(12);
      opacity: .6;
      > * {
        margin-top: 0;
      }
    }
  }

  &__content {
    width: calc(50% - 80px);

    @include below($desktop) {
      width: calc(50% - 40px);
    }

    @include below($tablet) {
      width: 100%;
    }

    a {
      color: $pink;
    }

    .btn--primary {
      color: $white;
    }

    .btn--secondary {
      color: $primary-color;

      &:hover {
        color: $pink;
      }
    }

    .btn--primary,
    .btn--secondary {
      margin-right: rem(10);
      margin-top: rem(10);
      margin-bottom: rem(10);
    }

    h1,
    h2 {
      @include above($tablet) {
        margin-bottom: rem(30);
      }
    }

    > *:first-child {
      margin-top: 0;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }


}
