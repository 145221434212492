.p-blog {
  > .hero {
    margin-top: 0;
  }

  > .section {
    > .constricted-xs {

      > section {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .tti {
        .tti__visual {
          @include below($tablet) {
            order: 0;
          }
        }
      }
    }
  }

  > section {
    &:last-child {

      &.instafeed {
        margin-bottom: 0;
      }
    }
  }

  .article-head {
    + .section {
      margin-top: 0;
    }
  }


  .article__header {
    margin-top: 40px;
    margin-bottom: 40px;

    h2 {
      margin-top: rem(20);
    }
  }

  .article__seperator {
    font-size: rem(16);

    &:last-child {
      display: none;
    }
  }

  .article__postdate,
  .article__category {
    font-size: rem(16);
    color: $pink;
    text-transform: uppercase;
  }
}

// PAGINATION

.blogs__navigation {

  @include below($tablet) {
    margin-bottom: rem(60);
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
    font-size: rem(20);
    font-family: $sans-font-family-alt;

    li {
      list-style: none;
      padding: 0 rem(10);
    }

    .current {
      color: $teal;
      border-bottom: rem(1) solid $teal;
      padding-bottom: rem(1);
    }
  }

  .blog-nav {
    color: $teal;

    &.blogs__navigation--disabled {
      pointer-events: none;
      cursor: not-allowed;
      color: rgba($black, .6);
    }
  }

  .blog-nav-first,
  .blog-nav-last {
    font-size: rem(14);
    font-weight: 600;
  }
}

// FEATURED

.blogs__featured {
  display: flex;
  justify-content: space-between;
  margin: 0 rem(20) rem(60) rem(20);

  @include below($tablet) {
    flex-wrap: wrap;
    margin: 0 0 rem(60) 0;
  }

  .blogs__featured-visual {
    width: calc(50% - 20px);
    position: relative;

    @include below($tablet) {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .article__label {
      margin: 0;
      padding: rem(8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      li {
        margin: 0 rem(4) rem(8) 0;
        display: inline-block;
        border-radius: rem(12);
        padding: rem(8);
        list-style: none;
        background: $secondary-color;

        span {
          font-size: rem(13) !important;
          line-height: rem(13) !important;
          color: $white;
        }
      }
    }
  }

  .blogs__featured-content {
    width: calc(50% - 20px);

    @include below($tablet) {
      width: 100%;
    }
  }

  .article__seperator {
    font-size: rem(16);

    &:last-child {
      display: none;
    }
  }

  .article__postdate,
  .article__category {
    font-size: rem(16);
    color: $pink;
    text-transform: uppercase;
  }
}

.blog-categories {
  .subnav__item {
    .active {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: rem(4);
        background: $pink;
        left: 0;
        bottom: -25px;

        @include below($tablet) {
          display: none;
        }
      }

      &:after {
        @include below($tablet) {
          left: 0;
          right: 0;
          opacity: 1;
        }
      }
    }
  }
}


// Blog block

.b-blog {
  h1,
  h2 {
    margin-bottom: rem(30);
  }

  &__meta {
    margin-bottom: rem(40);

    p {
      max-width: 60%;

      @include below($desktop-sm) {
        max-width: 100%;
      }
    }
  }

  &-items {

    &.box {
      border-radius: 0;

      @include below($tablet-xs) {
        padding: 0;
      }
    }

    .b-blog-items__inner {
      padding: rem(20);
      display: flex;

      @include below($tablet) {
        flex-wrap: wrap;
      }

      @include below($tablet-xs) {
        padding: 0;
      }

      .blog-item {
        padding: rem(20);
        width: 33.333%;

        @include below($tablet) {
          width: 100%;
        }
      }
    }
  }
}

.page-blog {
  .subnav__inner {
    @include below($tablet) {
      text-align: center;
    }
  }
}
