.custom-radio input[type="radio"],
.form-check input[type="radio"] {
  position: absolute;
  left: -9999em;

  & + label {
    user-select: none;
    display: inline-block;
    position: relative;
    padding-left: 35px;
    line-height: 22px;
    cursor: pointer;


    &::before {
      @include size(20px);
      content: '';
      display: block;
      position: absolute;
      transition: all .3s ease-in-out;
      border: 1px solid $orange;
      top: 0;
      left: 0;
    }
  }

  &:checked + label {
    &::before {
      background-color: $orange;
    }
  }
}

.form-check-inline {
  display: block;
}



.radiogroup-inline {
  margin-bottom: 20px;

  .custom-radio,
  .form-check {
    margin: 10px 0 0 0;
  }
}

.form-group {
  .form-check {
    margin: 10px 0 0 0;
  }
}


@include above($tablet) {
  .radiogroup-inline {
    .custom-radio,
    .form-check {
      display: inline-block;
    }
    .custom-radio + .custom-radio,
    .form-check + .form-check {
      margin: 0 0 0 45px;
    }
  }

  .form-group {
    .form-check {

      &.form-check-inline {
        display: inline-block;
      }

      + .form-check {
        &.form-check-inline {
          margin: 0 0 0 45px;
        }
      }
    }
  }
}
