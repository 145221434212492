.p-event {
  > .hero {
    margin-top: 0;
  }

  > .section {
    > .constricted {
      > .box {
        padding: rem(60);

        @include below($tablet) {
          padding: rem(20) 0;
        }

        > section {
          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .tti {
          .tti__visual {
            @include below($tablet) {
              order: 0;
            }
          }
        }
      }
    }
  }

  > section {
    &:last-child {

      &.instafeed {
        margin-bottom: 0;
      }
    }
  }
}
