.line {
  display: block;
  width: 1px;
  height: 68px;
  background: $orange;
  position: absolute;
  z-index: -1;
}

.tti {
  .line {
    height: 115px;
    left: -30px;
    top: -40px;
  }
}


.block--about {
  .line {
    left: -30px;
    top: 0;
  }
}
.article__header,
.section__header {
  .line {
    height: 115px;
    left: -30px;
    top: -40px;
  }
}


// .block--agenda {
//   .block__title {
//     position: relative;
//     display: inline-block;
//   }

//   .line {
//     right: -30px;
//     top: 0;
//   }
// }


.block--testimonial {
  blockquote {
    position: relative;
  }

  .line {
    left: -30px;
    top: -30px;
  }
}

