.text {

  &--left {
    text-align: left;
  }

  &--center {
    // text-align: center;
    .text__inner {
      margin: 0 auto;
    }
  }

  &--right {
    text-align: right;
  }

  &--medium {
    .text__inner {
      max-width: rem(640);
    }

    &.text--right {
      .text__inner {
        margin-left: calc(100% - 640px);
      }
    }
  }

  &--small {
    .text__inner {
      max-width: rem(400);
    }

    &.text--right {
      .text__inner {
        margin-left: calc(100% - 400px);
      }
    }
  }

  a {
    color: $pink;
  }

  .btn--primary {
    color: $white;
  }

  .btn--secondary {
    color: $primary-color;
  }

  .btn--primary,
  .btn--secondary {
    margin-right: rem(10);
    margin-top: rem(10);
    margin-bottom: rem(10);
  }
}
