.nav--primary {
  font-family: $sans-font-family-alt;
  font-weight: 600;
  text-transform: uppercase;
  background-color: $white;
  height: 0;
  margin: 0 -20px;
  // transition: height 0.3s ease-in-out;

  @include below($mobile-menu) {
    overflow: hidden;
  }

  li.is-active a {
    color: $secondary-color;
  }

  a {
    color: $primary-color;
    line-height: 1.3;

    &:hover {
      color: $secondary-color;
    }

    &::after {
      display: none;
    }
  }

  .nav__list {
    padding: 20px 0;
    margin-top: 6px;
    background: linear-gradient(to bottom, rgba(#eee, 0.5) 0%, #fff 100%);

    @include below($mobile-menu) {
      position: relative;
      overflow: hidden;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      margin-top: -1px;
      box-shadow: 0 0 3px rgba(black, 0.2);
    }
  }

  .nav__item {
    display: block;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    letter-spacing: ls(0.17, 16);
  }
}

@include above($mobile-menu) {
  .nav--primary {
    margin: 0;
    height: auto;

    .nav__list {
      border: none;
      padding: 0;
      margin: 0;
      background: none;

      &::before {
        display: none;
      }
    }

    .nav__item {
      display: inline;
      line-height: 1;
      text-align: left;
    }

    .nav__item + .nav__item {
      margin-left: 20px;
    }
  }
}
