.logo {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 44px;
  overflow: hidden;
  background-image: url('../img/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;

  @include above($tablet) {
    width: 102px;
    height: 56px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
  }

  &::after {
    display: none;
  }

  &--footer {
    width: 78px;
    height: 41px;
    background-image: url('../img/logo_white.svg');

    @include above($tablet) {
      width: 78px;
      height: 41px;
    }
  }
}
