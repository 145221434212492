.usp {

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }

  &__item {
    width: calc(33.33% - 40px);
    text-align: center;
    margin-bottom: rem(40);
    padding: 0;
    display: flex;

    @include below($desktop) {
      width: calc(33.33% - 20px);
    }

    @include below($tablet) {
      width: 100%;
    }


    &__inner {
      display: block;
      padding: rem(40);
      height: 100%;
      width: 100%;

      &:after {
        display: none;
      }

      @include below($desktop) {
        padding: rem(20);
      }
    }
  }





  &__visual {
    max-width: 150px;
    margin: 0 auto;
  }

}
