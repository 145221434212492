.article {
  &__inner {

  }

  &__header {
    margin-top: 80px;
    margin-bottom: 40px;
     position: relative;

    h2 {
      $fs: 20;
      font-size: px($fs);
      margin: .5em 0 .5em;
    }

    h3 {
      margin: 0;
      font-family: $serif-font-family;
      font-weight: 700;
      $fs: 16;
      font-size: px($fs);
      color: $secondary-color;
    }
  }

  .box {
    padding: 0;
  }

  &__content {
    padding: 20px;

    @include above($tablet) {
      padding: 30px;
    }

    @include above($desktop) {
      padding: 60px;
    }
  }
}


.article__content {
  p, ol, ul, img {
    margin: 1.5em 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ol, ul {
    padding: 0 0 0 1em;
  }

  #{$headers} {
    margin-top: 60px;

    &:first-child {
      margin-top: 0;
    }
  }
}


.article.single {
  margin-bottom: 40px;

  @include above($tablet) {
    margin-bottom: 80px;
  }

  @include above($desktop) {
    margin-bottom: 140px;
  }
}
