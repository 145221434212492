.testimonial {

  h1 {
    text-align: center;
    margin-bottom: rem(50);
  }

  blockquote {
    font-size: rem(24);
    font-family: $sans-font-family-alt;
    font-weight: 600;
    color: $yellow;

    margin-left: 0;
    margin-right: 0;

    max-width: rem(500);
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: rem(-25);
      top: rem(-30);

      width: rem(1);
      height: rem(68);
      background: $yellow;

    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;

    align-items: center;

    @include below($tablet) {
      display: block;
    }
  }

  &__visual {
    width: 50%;

    img {
      width: 100%;
    }

    @include below($tablet) {
      width: 100%;
    }
  }

  &__content {
    width: calc(50% - 80px);

    @include below($tablet) {
      width: calc(100% - 18px);
      margin-left: rem(18);
    }
  }

  .testimonial__company-image {

    a {
      opacity: 1;
      transition: all .3s ease;

      &:after {
        content: none;
        display: none;
      }

      &:hover {
        opacity: .8;
      }
    }

    img {
      max-width: rem(100);
      height: auto;
    }
  }
}
