.downloads {

  h1,
  h2 {
    margin-bottom: rem(40);
  }

  &-list {
    padding: 0;
    margin: 0;
  }

  &-item {
    list-style: none;
    margin-bottom: rem(10);

    a {
      background: rgba($orange, 1);
      border-radius: 12px;
      font-size: 15px;
      color: $white;
      letter-spacing: 0.18px;
      display: block;

      font-family: $sans-font-family-alt;
      font-weight: 600;
      text-transform: uppercase;

      padding: rem(20) rem(30);
      box-shadow: 0px 2px 4px 0px rgba(125,125,125,0.5);

      svg {
        vertical-align: middle;
      }

      &:hover {
        background: rgba($orange, .8);

        &:after {
          content: none;
          display: none;
        }
      }

    }
  }
}
