// ==========================================================================
// Fonts
// ==========================================================================
// Font-weights
// Thin (Hairline): 100
// Extra Light (Ultra Light): 200
// Light: 300
// Normal: 400
// Medium: 500
// Semi Bold (Demi Bold): 600
// Bold: 700
// Extra Bold (Ultra Bold): 800
// Black (Heavy): 900
// ==========================================================================

$base-font-path: if(variable-exists(base-font-path), $base-font-path, "../fonts/");

$fonts: (
  'butler': (
    (700, normal, "butler/butler_bold-webfont"),
  ),
  'montserrat': (
    (300, normal, "montserrat/montserrat-light-webfont"),
    (300, italic, "montserrat/montserrat-lightitalic-webfont"),
    (400, normal, "montserrat/montserrat-regular-webfont"),
    (400, italic, "montserrat/montserrat-italic-webfont")
  ),
  'social': (
    (700, normal, "social/icomoon"),
  )
);


@if(variable-exists(fonts)) {
  @each $name, $properties in $fonts {
    @each $weight, $style, $path in $properties {

      @font-face {
        font-family: $name;
        font-weight: $weight;
        font-style: $style;
        src: url($base-font-path + $path + ".woff2") format("woff2"),
             url($base-font-path + $path + ".woff") format("woff");
      }

    }
  }
}
