.filters {
  @include clearfix();
  margin: 20px -10px 30px;
}

.filter {
  position: relative;
  z-index: 99;
  width: calc(100% - 20px);

  &:focus {
    outline: 0;
  }

  & + & {
    margin-top: 10px;
  }

  &__list {
    top: 0;
    left: 0;
    margin: 0;
    list-style: none;
    background-color: $white;
    padding: 0 10px 20px;
    z-index: 0;
    display: block;

    label { white-space: nowrap; }
  }

  &__item {
    margin: 10px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    appearance: none;
    background-color: transparent;
    border: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 10px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eee;

    cursor: pointer;

    &:focus {
      outline: 0;
    }

    i {
      display: inline-block;
      position: relative;
      width: 14px;
      height: 14px;
      margin: -8px 10px 0 10px;


      &::before {
        content: '';
        @include size(14px);
        position: absolute;
        display: block;
        border-color: $orange;
        border-width: 1px 1px 0 0;
        border-style: solid;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(135deg);
      }
    }
  }
}

.filter.is-closed {
  z-index: 0;

  .filter__list {
    display: none;
  }
}

.filter.is-open {
  .filter__list {
    display: block;
  }
  .filter__button {
    i {
      margin-top: 8px;

      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}



@include above($tablet) {

  .filter {
    float: left;
    width: auto;

    & + & {
      margin-top: 0;
      margin-left: 20px;
    }

    &__list {
      top: 0;
      left: 0;
      background-color: $white;
      padding: 40px 10px 10px;
      box-shadow: 0 2px 4px rgba(#285E70, .30);
      position: absolute;
      min-width: 100%;
    }

    &__button {
      background-color: transparent;
    }
  }


}
