.two-col-content {

  &__inner {
    display: flex;
    justify-content: space-between;

    @include below($tablet) {
      display: block;
    }

  }

  &__item {
    width: calc(50% - 40px);

    @include below($tablet) {
      width: 100%;
      margin-bottom: rem(50);
    }

    img {
      width: 100%;
      height: auto;
      margin-bottom: rem(20);
    }
  }
}
