.event {
  color: $black;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  a {
    color: inherit;
    text-decoration: none;
    display: flex;

    transition: color .3s ease-in-out;

    &::after {
      display: none;
    }
  }

  &__icon {

    a,
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__content {
    width: calc(100% - 69px);
    padding-left: 20px;
    h1, h2 {
      @extend h1;
    }
  }

  &__date,
  &__description {
    margin: 0;
    // transition: color .3s ease-in-out;
  }

  h5.event__title,
  &__date {
    line-height: 1;
  }


  &__title {
    $fs: 16;
    font-size: px($fs);
    font-weight: 400;
    text-transform: uppercase;
    padding-top: 5px;
    margin-bottom: rem(10);
    // transition: color .3s ease-in-out;
  }

  &__date {
    $fs: 14;
    font-size: px($fs);
    color: $secondary-color;
    font-family: $serif-font-family;
    font-weight: 700;
  }

  &__description {
    $fs: 14;
    font-size: px($fs);
    font-weight: 300;
    margin-top: 10px;
  }

  &:hover {
    cursor: pointer;

    a {
      color: $secondary-color;
    }

    .event__title {
      color: inherit;
    }
    // .event__icon {
    //   @include colorize-icon($pink);
    // }
  }
}



@include above($tablet-xs) {
  .event {
    display: block;

    a {
      display: block;
    }

    &__icon {
      margin-bottom: 16px;
    }

    &__title {
      padding-top: 0;
    }

    &__content {
      width: 100%;
      padding-left: 0;
    }
  }
}


@include above($tablet) {
  .event {
    &__icon {
      margin-bottom: 16px;
      margin-left: auto;
      margin-right: auto;
    }

    &__title {
      $fs: 16;
      font-size: px($fs);
    }

    &__date {
      $fs: 14;
      font-size: px($fs);
    }

    &__description {
      $fs: 14;
      font-size: px($fs);
      margin-top: 16px;
    }
  }
}

// Events block

.b-event {

  h1,
  h2 {
    margin-bottom: rem(30);
  }

  &__meta {
    margin-bottom: rem(40);

    p {
      max-width: 60%;

      @include below($desktop-sm) {
        max-width: 100%;
      }
    }
  }

  &-items {

    &.box {
      border-radius: 0;

      @include below($tablet-xs) {
        padding: 0;
      }
    }

    .events-overview__inner {
      padding: rem(20);

      @include below($tablet-xs) {
        padding: 0;
      }

      .event {
        padding: rem(20);
      }
    }
  }
}


