.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.custom-select {
  text-align: left;
  position: relative;
}



.fake-select {
  position: relative;
  user-select: none;


  &__field {
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    padding: 11px 30px 11px 16px;
    perspective: 500;
    cursor: pointer;

    @include above($tablet) {
      white-space: nowrap;
    }

    i {
      display: block;
      width: 14px;
      height: 7px;
      right: 14px;
      overflow: hidden;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) scaleY(1);
      transform-style: preserve-3d;
      transition: transform .3s ease-in-out;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        bottom: 2px;
        left: 2px;
        border-style: solid;
        border-color: #000;
        border-width: 1px 1px 0 0;
        transform: rotate(135deg);
      }
    }

  }

  &__list {
    z-index: 99;
    position: absolute;
    border: 1px solid #E0E0E0;
    border-top: none;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $white;
    list-style: none;
    display: none;
    width: 100%;
    font-size: .95em;
    padding: 0;
    margin-top: 0;
    box-shadow: 0 3px 5px rgba($black, .1);

    @include above($tablet) {
      white-space: nowrap;
    }

    li {
      cursor: pointer;
      padding: 10px 30px 10px 10px;
      margin: 0 0 1px 0;
      display: block;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
      }

      &.is-selected,
      &:hover {
        background-color: rgba($yellow, .2);
      }

      span:first-child {
        flex-grow: 1;
      }
    }
  }



  &.is-shown {

    .fake-select__field {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .fake-select__field i {
      transform: translateY(-50%) scaleY(-1);
    }

    .fake-select__list {
      display: block;
    }

  }
}

