.site-footer {
  background-color: $primary-color;
  color: $white;
  padding: 20px 0 40px 0;

  &__bottom,
  &__top {
    padding: 20px 0;
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid rgba($white, .5);
  }

  &__bottom__col {
    text-align: center;
    width: 50%;

    &:nth-child(1) {
      text-align: left;
    }

    &:nth-child(2) {
      text-align: right;
    }

    &:nth-child(3) {
      padding-top: 40px;
      width: 100%;
    }
  }

  .nav__list a::after {
    background-color: $secondary-color;
  }
}


@include above($tablet) {
  .site-footer {
    &__bottom {

      align-items: center;
      justify-content: space-between;
    }

    &__bottom__col {
      width: 33.333%;

      &:nth-child(1) {
        order: 1;
        text-align: left;
      }

      &:nth-child(2) {
        order: 3;
        text-align: right;
      }

      &:nth-child(3) {
        width: 33.333%;
        padding: 0;
        order: 2;
      }
    }
  }
}
