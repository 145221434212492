.employee-slider {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: space-between;

  @include above(900px) {
    width: 95%;
  }

  @include above(1320px) {
    width: auto;
  }

  .employee-slider__slide {
    display: none;

    &:nth-child(1) {
      display: block;
    }

    @include above($tablet-xs) {
      &:nth-child(1),
      &:nth-child(2) {
        display: block;
      }
    }

    @include above(900px) {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        display: block;
      }
    }

    @include above($desktop) {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5)  {
        display: block;
      }
    }
  }

  &.slick-initialized {
    .employee-slider__slide {
      display: block;
    }
  }
}

.slick-arrow {
  @include size(60px);
  border: none;
  background: none;
  appearance: none;
  position: absolute;
  top: 50%;
  margin-top: -60px;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
  }

  &.slick-prev {
    background-image: url('../img/icons/icn-chevron-left.svg');
    left: -40px;
    right: auto;
  }

  &.slick-next {
    background-image: url('../img/icons/icn-chevron-right.svg');
    left: auto;
    right: -40px;
  }
}
