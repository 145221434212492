// ==========================================================================
// Layout
// Layout rules divide the page into sections. Layouts hold one or more
// modules together.
// ==========================================================================

.constrict {
  max-width: rem($desktop-md);
  margin: auto;
  padding: 0 rem(30);
}

.section {
  &.text + .team-slider {
    margin-top: rem(-40);

    @include below($desktop) {
      margin-top: rem(-10);
    }

    @include below($tablet) {
      margin-top: rem(-20);
    }
  }
}
