.subnav {

  background-color: $teal;
  color: $white;

  padding: rem(25) 0;

  a {
    color: inherit;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__item {

    padding: 0 rem(20);

    @include below($tablet) {
      width: 100%;
      display: block;

      padding: 0;
    }

    > a {
      font-size: rem(18);
    }
  }

  &__level2 {
    padding-left: 0;
    margin: rem(10) 0;

    li {
      list-style: none;

      svg {
        margin-right: rem(5);
      }

      a {
        font-size: rem(14);
        line-height: rem(24);
      }
    }
  }

}
