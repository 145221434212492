// ==========================================================================
// Variables
// ==========================================================================

$root-box-sizing: border-box;

$mobile: 480px;
$tablet-xs: 600px;
$tablet: 769px;
$tablet-fix: 870px;
$desktop-sm: 990px;
$desktop: 1100px;
$desktop-md: 1280px;
$desktop-hd: 1440px;
$hd: 1650px;
$desktop-xxl: 2000px;

$mobile-menu: $desktop-md;

$base-margin: 20px;

// Colors
$black: #4a4a4a;
$white: #fff;
$red: red;
$teal: #285e70;
$pink: #dca4b2;
$yellow: #e8cb52;
$orange: #ed7145;
$primary-color: $teal;
$secondary-color: $pink;
$green: #008939;

$error-color: $red;

// Paths
$base-path: "../";
$base-font-path: $base-path + "fonts/";
$base-img-path: $base-path + "img/";

// Fonts
$sans-font-family: "montserrat", sans-serif;
$sans-font-family-alt: "work-sans", sans-serif;
$serif-font-family: "butler", serif;
$ff-system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$ff-primary: $sans-font-family;
$ff-mono: SFMono-Regular, Consolas, "Liberation Mono", Menlo, Courier, monospace;

// Transitions
$base-transition: "all .3s ease";

// Variables for functions
$vw-base: $desktop-hd;
$default-font-size: 16;
