.section-tti-slider {
  h1 {
    margin-bottom: rem(50);
  }
}

.tti-slider {

  &.slick-initialized {
    .tti {
      display: block;
    }
  }

  .tti {
    padding: 0 40px;
    display: none;

    &:first-child {
      display: block;
    }
  }

  .slick-arrow {
    margin-top: 0;
    transform: translateY(-50%);
  }

  .slick-prev {
    @include below($desktop-md) {
      left: -28px;
    }
  }

  .slick-next {
    @include below($desktop-md) {
      right: -28px;
    }
  }


}
