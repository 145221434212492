.nav--social {
  color: $white;

  a {
    @include size(20px);
    color: inherit;
    overflow: hidden;
    display: inline-block;

    &::after {
      display: none;
    }

    .social {
      display: block;
      width: 100%;
      height: 100%;
    }

  }
}

.social {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: inherit;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &--instagram::before {
    content: "\e900";
  }
  &--facebook::before {
    content: "\ea90";
  }
  &--instagram-alt::before {
    content: "\ea92";
  }
  &--linkedin::before {
    content: "\eaca";
  }
}
