// ==========================================================================
// Mixins
// ==========================================================================

@mixin colorize-icon($color) {
  @supports(mix-blend-mode: overlay) {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: $color;
      position: absolute; top: 0;
      left: 0;
      z-index: 1;
      mix-blend-mode: overlay;
    }

    img {
      filter: grayscale(100%);
    }
  }
}

@function px($value) {
  @return $value * 1px;
}

@mixin btn-hover() {
  &:not([disabled]):hover,
  &:not([disabled]):focus {
    @content;
  }
}

@mixin notch-top-left($size) {
  clip-path:
    polygon(
      0 $size,                    /* top left */
      $size 0,                    /* top left */
      100% 0,                     /* top right */
      100% 0,                     /* top right */
      100% 100%,                  /* bottom right */
      100% 100%,                  /* bottom right */
      0 100%,                     /* bottom left */
      0 100%                      /* bottom left */
    );
}

@mixin notch-top-left-bottom-left($size) {
  clip-path:
    polygon(
      0 $size,                    /* top left */
      $size 0,                    /* top left */
      100% 0,                     /* top right */
      100% 0,                     /* top right */
      100% 100%,                  /* bottom right */
      100% 100%,                  /* bottom right */
      $size 100%,                 /* bottom left */
      0 calc(100% - #{$size})     /* bottom left */
    );
}

@mixin notch-bottom-right($size) {
  clip-path:
    polygon(
      0 0,                        /* top left */
      0 0,                        /* top left */
      100% 0,                     /* top right */
      100% 0,                     /* top right */
      100% calc(100% - #{$size}), /* bottom right */
      calc(100% - #{$size}) 100%, /* bottom right */
      0 100%,                     /* bottom left */
      0 100%                      /* bottom left */
    );
}

@mixin notch-top-left-bottom-right($size) {
  clip-path:
    polygon(
      0 $size,                    /* top left */
      $size 0,                    /* top left */
      100% 0,                     /* top right */
      100% 0,                     /* top right */
      100% calc(100% - #{$size}), /* bottom right */
      calc(100% - #{$size}) 100%, /* bottom right */
      0 100%,                     /* bottom left */
      0 100%                      /* bottom left */
    );
}

@mixin lines-vertical($color) {
  background-image: linear-gradient(90deg, $color 1px, transparent 1px);
  background-size: 10px 10px;
  background-position: 0 0;
}

@mixin lines-horizontal($color) {
  background-image: linear-gradient(0deg, $color 2px, transparent 2px);
  background-size: 8px 8px;
  background-position: 0 -6px;
}

@mixin fntSize($fs, $lh: null, $ls: null) {
  font-size: rem($fs);
  line-height: if($lh, ($lh/$fs), null);
  letter-spacing: if($ls, em($ls, $fs), null);
}

@mixin fnt($font-family, $fs, $fw:400, $lh: null, $ls: null) {
  font-family: $font-family;
  font-weight: $fw;
  @include fntSize($fs, $lh, $ls);
}

@function lh($lh, $fs) {
  @return ($lh/$fs);
}

@function ls($ls, $fs) {
  @return em($ls, $fs);
}


