.cta-block {
  margin-top: -20px;

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}



@include above($desktop) {
  .cta-block {
    margin-top: -60px;
  }
}
