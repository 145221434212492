%btn {
  font-family: $sans-font-family-alt;
  font-weight: 600;
  display: inline-block;
  background-color: transparant;
  text-decoration: none;
  min-height: 40px;
  min-width: 170px;
  font-weight: 600;
  font-size: 15px;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  box-shadow: 0 2px 4px rgba(#7D7D7D, .5);
  transition: all .3s ease-in-out;
  cursor: pointer;

  &::after {
    display: none;
  }
}

.btn {
  @extend %btn;

  &--primary {
    @extend %btn;

    background-color: $primary-color;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: $pink;
      color: $white;
    }
  }

  &--secondary {
    @extend %btn;

    background-color: $white;
    color: $primary-color;

    &:hover,
    &:focus,
    &:active {
      background-color: $white;
      color: $secondary-color;
    }
  }
}
