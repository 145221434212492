.menu-toggle {
  position: absolute;
  right: 20px;
  top: 14px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.hamburger {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-in-out;

  &__inner,
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 29px;
    height: 3px;
    background-color: $primary-color;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out;
  }

  &__inner {
  }

  &::before {
    margin-top: -8px;
  }

  &::after {
    margin-top: 8px;
  }
}

.is-open .hamburger {
  transform: translate(-50%, -50%) rotate(90deg);

  &__inner {
    width: 0;
    opacity: 0;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
    margin-top: 0;
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
    margin-top: 0;
  }
}
