.blog {

  &-item {
    width: 100%;

    a {
      &:after {
        content: none;
        display: none;
      }

      &:hover {
        &:after {
          content: none;
          display: none;
        }

        .blog__title {
          color: $pink;
        }
      }
    }

    .article__seperator {
      font-size: rem(16);

      &:last-child {
        display: none;
      }
    }

    .article__postdate,
    .article__category {
      font-size: rem(16);
      color: $pink;
      text-transform: uppercase;
    }
  }

  &__visual {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    .article__label {
      margin: 0;
      padding: rem(8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      li {
        margin: 0 rem(4) rem(8) 0;
        display: inline-block;
        border-radius: rem(12);
        padding: rem(8);
        list-style: none;
        background: $secondary-color;

        span {
          font-size: rem(13) !important;
          line-height: rem(13) !important;
          color: $white;
        }
      }
    }
  }

  &__title {
    font-family: $sans-font-family-alt;
    font-size: rem(20);
    margin-top: rem(15);
    transition: all .3s ease;
  }

  &__meta {
    font-size: rem(14);
    line-height: rem(24);
    margin: rem(12) 0;

    .article__postdate,
    .article__category {
      font-size: inherit !important;
    }
  }

  &__description {
    margin: rem(8) 0;
    line-height: 25px;
  }
}


.blogs {
  &__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .blog-item {
      width: calc(33.33% - 40px);
      margin-right: rem(20);
      margin-left: rem(20);
      margin-bottom: rem(40);

      @include below($desktop) {
        width: calc(50% - 40px);
      }

      @include below($tablet) {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}
