.practical-info {

  .section__inner {
    > h1,
    > h2 {
      margin-bottom: rem(40);
    }
  }

  &-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-item {

    margin-bottom: rem(20);
    width: calc(25% - 20px);
    min-width: calc(25% - 20px);
    padding: 0 rem(10px);
    flex: 1;

    @include below($desktop) {
      width: calc(50% - 20px);
      min-width: calc(50% - 20px);
    }

    @include below($mobile) {
      width: 100%;
      min-width: 100%;
    }

    .practical-info-title {
      color: $pink;
      font-size: rem(16);
    }

    a {
      font-weight: 600;
    }
  }

  .addthis_share {
    height: auto;
    margin-top: rem(16);

    .addthis_inline_share_toolbox {
      position: static;

      span.at-icon-wrapper {
        width: rem(22) !important;
        height: rem(22) !important;

        svg {
          width: rem(22) !important;
          height: rem(22) !important;
        }
      }
    }
  }
}
