a {
  position: relative;
  display: inline-block;
  color: $black;
  text-decoration: none;
  transition: all .3s ease-in-out;

  &::after {
    content: '';
    background-color: currentColor;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    opacity: 0;
    height: 1px;
    transition: all .3s ease-in-out;
  }

  &:hover {
    &::after {
      left: 0;
      right: 0;
      opacity: 1;
    }
  }
}
