.nav--footer {
  font-family: Avenir, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: $white;

  a {
    color: inherit;
  }

  .nav__list {

    .submenu {
      font-family: Montserrat, sans-serif;
      font-weight: 300;
      text-transform: none;
      margin-top: 15px;

      .nav__item {
        display: block;
      }

      .nav__item > a {
        display: inline-block;
        line-height: 30px;
      }
    }

    > .nav__item {
      margin-bottom: 30px;
      width: 100%;
      position: relative;
    }
  }


}

@include above($mobile) {
  .nav--footer {
    .nav__list {
      display: flex;
      flex-wrap: wrap;

      > .nav__item {
        width: 50%;
      }
    }

  }
}

@include above($tablet) {
  .nav--footer {
    .nav__list {
      > .nav__item {
        width: 33.333%;
      }
    }
  }
}


@include above($desktop-sm) {
  .nav--footer {
    .nav__list {
      > .nav__item {
        width: 25%;
      }
    }
  }
}


@include above($desktop) {
  .nav--footer {
    .nav__list {
      > .nav__item {
        margin-bottom: 0;
        width: calc(100%/7);
      }
    }
  }
}




.toggle-submenu {
  @include size(25px);
  display: inline-block;
  position: absolute;
  right: 0;
  top: -2px;

  &::before {
    content: '';
    display: block;
    @include size(10px);
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: $white;
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%, -50%) rotate(-135deg);
  }
}

.is-closed .toggle-submenu::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
