.cc-window {
  padding: rem(32) !important;
  background-color: $secondary-color !important;
  justify-content: center !important;
  position: relative;
  overflow: visible !important;

  .cc-message {
    font-family: $ff-primary;
    font-size: rem(14);
    padding-right: rem(64) !important;
    max-width: rem(1340);

    @media screen and (max-width: rem(415)) {
      padding-right: 0 !important
    }
  }

  .cc-compliance {
    text-transform: uppercase;

    
    .cc-btn {
      font-family: $ff-primary;
      font-weight: 400 !important;
      font-size: rem(12);
      line-height: rem(20) !important;
      border-radius: rem(12) !important;
      padding: rem(10) rem(20);
      transition: ease all 500ms;
      background: $secondary-color;
      border: 1.25px solid $white;
      color: $white;

      &:hover {
        color: $secondary-color !important;
        background: $white !important;
        text-decoration: none;

        &:after {
          display: none;
        }
      }
    }
  }
}

.cc-cookie {
  &:after {
    z-index: -1;
    content:'';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background: $secondary-color;
  }  
}