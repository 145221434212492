.box {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 2px 9px rgba(#B0B0B0, .5);
  padding: 20px;

  @include above($tablet) {
    border-radius: 15px;
  }

  @include above($desktop) {
    border-radius: 30px;
  }
}
