.article__footer {
  display: flex;

  @include below(500px) {
    display: block;
  }

  .author {
    width: 60%;

    @include below(500px) {
      width: 100%;
    }

    + .article__share {
      padding-left: rem(40);
      margin-left: rem(40);
      border-left: rem(1) solid $orange;

      @include below(500px) {
        margin-left: 0;
        padding-left: rem(20);
      }
    }
  }

  .article__share {
    width: 40%;

    @include below(500px) {
      width: 100%;
    }

    h4 {
      margin-top: rem(15);
      color: $black;
    }

    .addthis_share {
      height: auto;
      margin-top: rem(10);

      .addthis_inline_share_toolbox {
        position: static;

        span.at-icon-wrapper {
          width: rem(18) !important;
          height: rem(18) !important;

          svg {
            width: rem(18) !important;
            height: rem(18) !important;
          }
        }
      }
    }
  }
}

.author {

  display: flex;
  align-items: center;

  &-bg-image {
    width: rem(75);
    height: rem(75);

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    border-radius: 100%;

    margin-right: rem(20);
  }

  a {
    color: $pink;
  }
}
