.section-testimonial-slider {
  h1 {
    margin-bottom: rem(50);
  }
}

.testimonial-slider {

  &.slick-initialized {
    .tti {
      display: block;
    }
  }

  &.box {
    border-radius: 0;
    padding: rem(50) 0;
  }

  iframe {
    // min-height: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  .tti {
    padding: 0 rem(60);
    display: none;

    &:first-child {
      display: block;
    }
  }

  .slick-arrow {
    margin-top: 0;
    transform: translateY(-50%);
    z-index: 50;

    &.slick-prev {
      left: -80px;
      @include below($desktop-hd) {
        left: -5px;
      }
    }

    &.slick-next {
      right: -80px;
      @include below($desktop-hd) {
        right: -5px;
      }
    }
  }


}
