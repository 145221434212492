@include above($tablet-xs) {
  .values-wrapper {
    display: grid;
    grid-template-columns: 31.333% 3% 31.333% 3% 31.333%;
  }

  .block__description {
    max-width: 465px;
  }

  .value {
    &:nth-child(6n + 1) {
      margin: 0 0 -20px 0;
      grid-column: 3 / span 1;
      // grid-row: 1 / span 1;
    }

    &:nth-child(6n + 2) {
      grid-column: 1 / span 1;
      // grid-row: 2 / span 1;
    }

    &:nth-child(6n + 3) {
      grid-column: 5 / span 1;
      // grid-row: 2 / span 1;
    }

    &:nth-child(6n + 4) {
      grid-column: 1 / span 1;
      // grid-row: 3 / span 1;
    }

    &:nth-child(6n + 5) {
      grid-column: 5 / span 1;
      // grid-row: 3 / span 1;
    }

    &:nth-child(6n + 6) {
      margin: -20px 0 0 0;
      grid-column: 3 / span 1;
      // grid-row: 4 / span 1;
    }
  }
}



.value {
  margin: 15px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__icon {
    @include circle(69px);
    overflow: hidden;
    position: relative;
    box-shadow: 0 2px 4px rgba(black, .35);
    background-color: $white;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $orange;

    img {
      max-width: 80% !important;
      height: auto !important;
    }
  }

  &__content {
    width: calc(100% - 69px);
    padding-left: 20px;
  }

  &__title {
    $fs: 16;
    font-size: px($fs);
    font-weight: 300;
    text-transform: uppercase;
    font-family: $sans-font-family-alt;
  }

  &__description {
    $fs: 14;
    font-size: px($fs);
  }

  &:hover {
    // cursor: pointer;
    .value__icon {
      border-color: $pink;
      @include colorize-icon($pink);
    }
  }
}



@include above($tablet-xs) {
  .value  {
    display: block;
    text-align: center;

    &__icon {
      @include circle(96px);

      // img {
      //   max-width: 60px;
      // }
    }

    &__content {
      width: auto;
      padding-left: 0;
      margin-top: 10px;
    }

    &__title {
      $fs: 14;
      font-size: px($fs);
    }

    &__description {
      $fs: 12;
      font-size: px($fs);
      margin-top: .5em;
    }

  }
}

@include above($tablet) {
  .value {

    &__title {
      $fs: 16;
      font-size: px($fs);
    }

    &__description {
      $fs: 14;
      font-size: px($fs);
    }
  }
}

@include above($desktop) {
  .value {

    &__title {
      $fs: 18;
      font-size: px($fs);
    }

    &__description {
      $fs: 16;
      font-size: px($fs);
    }
  }
}


@include above($desktop-hd) {
  .value {

    &__title {
      $fs: 20;
      font-size: px($fs);
    }
  }
}
