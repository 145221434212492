.nav__dropdown {
  @include below($mobile-menu) {
    display: none;
  }

  @include above($mobile-menu) {
    position: absolute;
    background: $white;
    padding: rem(32) !important;
    left: 50%;
    transform: translateX(-50%) translateY(-5%) scale(0.9) translateZ(0);
    min-width: 175%;
    pointer-events: none;
    opacity: 0;
    transition: 150ms;
    white-space: nowrap;
  }

  li {
    display: block !important;
    margin: 0 auto !important;
    width: 100%;
    text-align: center !important;

    a {
      @include below($mobile-menu) {
        color: $secondary-color !important;
      }
    }

    &:not(:last-of-type) {
      @include above($mobile-menu) {
        margin-bottom: rem(12) !important;
      }
    }
  }
}

.nav__list {
  .nav__item {
    position: relative;

    &:hover {
      .nav__dropdown {
        @include above($mobile-menu) {
          backface-visibility: hidden;
          transform: translateX(-50%) translateY(0) scale(1) translateZ(0);
          pointer-events: all;
          opacity: 1;
        }
      }
    }
  }
}

// toggle class

.js-toggle-nav {
  position: absolute;
  right: rem(16);
  transition: 250ms ease;

  @include above($mobile-menu) {
    display: none;
  }
}

.js-hide-drop {
  transform: rotate(180deg);
}

.js-show-item {
  display: block !important;
}
