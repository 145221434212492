.jobs-overview {
  padding: 30px 30px 15px;
  margin-top: 20px;

  &__cta {
    text-align: center;
  }

  @include above($tablet) {
    padding: 60px 60px 30px;
    margin-top: 40px;
  }
}

.jobs {
  list-style: none;
  padding: 0;
  margin: 20px 0;

  @include above($tablet) {
    margin: 20px 0 40px;
  }

  &__item {
    &, & a {
      display: block;
    }
  }
}

.job {
  font-family: $sans-font-family-alt;
  border-bottom: 1px solid rgba(#4A4A4A, 1);
  display: flex;
  align-items: center;
  padding: 30px 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    opacity: 0;
    top: 0;
    right: -30px;
    bottom: 0;
    left: -30px;
    background-color: rgba($pink, .25);
    z-index: 0;
    transition: opacity .3s ease-in-out;

    @include above($tablet) {
      right: -60px;
      left: -60px;
    }
  }

  &:hover {
    &::before {
      opacity: 1
    }
  }

  &__title {
    text-align: left;
    flex-grow: 1;
  }

  &__description {
    text-align: right;
  }

  &__location,
  &__company,
  &__function {
    margin: 0;
  }

  &__function {
    $fs: 20;
    font-size: px($fs);
  }

  &__company {
    $fs: 16;
    font-size: pc($fs);
    font-family: $serif-font-family;
    font-weight: 700;
    letter-spacing: ls(.59, $fs);
    color: $secondary-color;
  }

  &__function + &__company {
    margin-top: 10px;
  }
}


