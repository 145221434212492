.section {
  margin: 40px 0;

  &__header {
    position: relative;
  }




  @include above($desktop) {
    margin: 80px 0;
  }
}

.section--consultants {
  background-color: #FDFDFD;
  text-align: center;
  padding: 50px 0;

  h1 {
    margin-bottom: 50px;
  }
}


.inschrijven {
  .section--intro {
    margin-bottom: 0;
  }
  .section--content {
    margin-top: 0;
  }
}


.section__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
