.faq {

  h1,
  h2 {
    margin-bottom: rem(40);
  }

  &-items {
    padding: rem(50) rem(60);

    &.box {
      border-radius: 0;
    }

    @include below($tablet) {
      padding: rem(30) rem(30);
    }
  }

  &-item {
    padding-bottom: rem(25);
    margin-bottom: rem(30);
    border-bottom: rem(1) solid $black;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  &-question {
    font-size: rem(20);
    color: $teal;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @include below($tablet) {
      font-size: rem(18);
    }

    &.is-collapsed {
      svg {
        transform: rotate(-180deg);
      }
    }

    svg {
      transform: rotate(0);
      transition: all .3s ease;
    }
  }

  &-content {
    max-width: calc(100% - 20px);
    display: none;

    @include below($tablet) {
      max-width: 100%;
    }
  }
}
