$textfields: 'input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"]';
$textfields-focus: 'input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus';
$textfields-hover: 'input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover';
$textfields-active: 'input[type="color"]:active, input[type="date"]:active, input[type="datetime"]:active, input[type="datetime-local"]:active, input[type="email"]:active, input[type="month"]:active, input[type="number"]:active, input[type="password"]:active, input[type="search"]:active, input[type="tel"]:active, input[type="text"]:active, input[type="time"]:active, input[type="url"]:active, input[type="week"]:active';
$textfields-disabled: 'input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled';

$textinputs: $textfields + ', textarea';
$textinputs-focus: $textfields-focus + ', textarea:focus';
$textinputs-hover: $textfields-hover + ', textarea:hover';
$textinputs-active: $textfields-active + ', textarea:active';
$textinputs-disabled: $textfields-disabled + ', textarea:disabled';

$buttons: 'input[type="submit"], input[type="button"], input[type="reset"], button';
$buttons-focus: 'input[type="submit"]:focus, input[type="button"]:focus, input[type="reset"]:focus, button:focus';
$buttons-hover: 'input[type="submit"]:hover, input[type="button"]:hover, input[type="reset"]:hover, button:hover';
$buttons-active: 'input[type="submit"]:active, input[type="button"]:active, input[type="reset"]:active, button:active';
$buttons-disabled: 'input[type="submit"]:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, button:disabled';

form {
  max-width: 870px;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

.form-label {
  margin-bottom: rem(20);
}

.form-label[hidden=hidden] {
  display: none !important;
}

form button[type="submit"] {
  margin-top: 20px;
}

#{$textinputs} {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 11px 16px;
  display: block;
  width: 100%;
}

// select {
//   padding: 10px 10px;
// }

textarea {
  height: 225px;
}

.form-group {
  margin-bottom: 25px;
}

.field {
  width: 100%;
  margin-bottom: 25px;

  label {
    display: none;
  }

  &.error {
    label {
      color: $red;
    }

    input,
    textarea {
      border: 1px solid $red;
    }
  }
}

#{$textinputs} {
  &.has-error {
    border: 1px solid $red;

    label {
      color: $red;
    }
  }
}

.help-block {
  &.errors {
    padding-left: 0;
    list-style: none;
    font-size: 12px;
    color: $red;
  }
}

.alert {
  &.alert-danger {
    border: rem(1) solid $red;
    padding: 0 rem(20);
    border-radius: rem(5);
    color: $red;
  }

  &.alert-success {
    border: rem(1) solid $green;
    padding: 0 rem(20);
    border-radius: rem(5);
    color: $green;
  }
}



@include above($mobile) {
  .fieldrow--col-2 {
    display: flex;
    justify-content: space-between;

    .field {
      width: calc(50% - 10px);
    }
  }
}


@include above($tablet-xs) {
  .fieldrow--col-2 .field {
    width: calc(50% - 20px);
  }
}

@include above($tablet) {
  .fieldrow--col-2 .field {
    width: calc(50% - 30px);
  }
}

@include above($desktop-sm) {
  .fieldrow--col-2 .field {
    width: calc(50% - 40px);
  }
}

@include above($desktop) {
  .fieldrow--col-2 .field {
    width: calc(50% - 50px);
  }
}

.form-container,
.thankyou {
  &.hide {
    display: none;
  }
}
