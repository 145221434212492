.nav {

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: inline-block;

    a {
      text-decoration: none;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}
