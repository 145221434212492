.addthis_share { 

    position: relative;
    height: 50px;

    .addthis_inline_share_toolbox {
        position: absolute;
        right: 25px;
    }

}