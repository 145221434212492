.instafeed {

  h1 {
    text-align: center;
    margin-bottom: rem(50);
  }

  &-slider {
    display: flex;

    a {
      opacity: 1;
      transition: all .2s ease;

      &:after {
        content: none;
        display: none;
      }

      &:hover {
        opacity: .8;
      }
    }

    a {
      display: block !important;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}
